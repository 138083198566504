
// GENERAL
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';

// SERVICES
import { AppsettingsService } from '../app-settings/app-settings.service';
import { NotificationsService } from '../notifications/notifications.service';

// INTERFACES
import * as int from '../../interfaces/erp.interface'

@Injectable({
    providedIn: 'root'
})
export class ErpService {

    constructor(
        private appSettingsService:AppsettingsService,
        private httpClient:HttpClient,
        private Router:Router,
        private NotificationsService:NotificationsService
    ) {}

    // Object to hold erp information offline. When a component tries to check what erp system
    // we will return the object, if it exists.
    private erpSystem: BehaviorSubject<int.ErpSystemType | null> = new BehaviorSubject<int.ErpSystemType | null>(null)
    public erpSystem$: Observable<int.ErpSystemType | null> = this.erpSystem.asObservable()
    systemCheckRan: boolean = false

    setActiveErpSystemTypeObservable(ErpSystemType: int.ErpSystemType | null) {
        this.erpSystem.next( ErpSystemType )
    }

    async setActiveErpSystemType( ) {
        let url = `${this.appSettingsService.getApiServerUrlNew()}erp/active-system-type`
        await lastValueFrom<any>(this.httpClient.get<any>(url)).then( ( data: int.ErpSystemType ) => {

            this.setActiveErpSystemTypeObservable( data )
        })
        .catch(( err: any ) => {

            // If this fails, something is really broken. We should go to an error page
            this.systemCheckFailed()
        })

        this.systemCheckRan = true
    }

    async getActiveErpSystemType():Promise<int.ErpSystemType | null> {

        // Use this to get the system type without having to subscribe to it, regular service style
        // If we dont have a value before it has been set, try to get it. 
        // This can only happen once since we dont want to spam the backend.
        if ( this.erpSystem.getValue() === null && !this.systemCheckRan ) {

            await this.setActiveErpSystemType()
            return this.erpSystem.getValue()

        } else if (this.erpSystem.getValue() === null && this.systemCheckRan) {

            this.systemCheckFailed()
            return null

        } else {
            return this.erpSystem.getValue()
        }
    }

    systemCheckFailed() {
        this.NotificationsService.addNotificationError('Kunde inte hämta systemtyp')
        this.Router.navigate(['/']);
    }

    public async isFortnox() {
        
        const erpSystemType:int.ErpSystemType | null = await this.getActiveErpSystemType()
        if ( erpSystemType === null ) return null

        if ( erpSystemType.code == 'fortnox' ) return true
        
        return false
    }

    public async isVismaNet() {
        
        const erpSystemType:int.ErpSystemType | null = await this.getActiveErpSystemType()
        if ( erpSystemType === null ) return null

        if ( erpSystemType.code == 'visma-net' ) return true

        return false
    }
}
